/* Button style */
.btn {
  font-size: 16px;
  font-family: $primary-font;
  text-transform: capitalize;
  padding: 15px 40px;
  border-radius: 0;
  font-weight: 500;
  border: 0;
  position: relative;
  z-index: 1;
  transition: .2s ease;
  overflow: hidden;

  &::before {
    position: absolute;
    content: "";
    height: 80%;
    width: 100%;
    left: 0;
    bottom: 10%;
    z-index: -1;
    transition: transform .2s ease-in-out;
    transform-origin: top;
    transform: scaleY(0);
  }

  &:focus {
    outline: 0;
    box-shadow: none !important;
  }

  &:active {
    box-shadow: none;
  }

  &:hover {

    &::before {
      transform: scaleY(1);
      transform-origin: bottom;
    }
  }
}

.btn-sm {
  font-size: 14px;
  padding: 10px 35px;
}

.btn-xs {
  font-size: 12px;
  padding: 5px 15px;
}

.btn-primary {
  background: $primary-color;
  color: $white;

  &::before {
    background: $white;
  }

  &:active {
    background: $primary-color !important;
    color: $primary-color;

    &::before {
      height: 80%;
    }
  }

  &:hover {
    background: darken($color: $primary-color, $amount: 10);
    color: $primary-color;
  }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: $white;
  background-color: darken($color: $primary-color, $amount: 10);
  border-color: darken($color: $primary-color, $amount: 10);
}

.btn-secondary {
  background: $white;
  color: $primary-color;
  border: 1px solid $white;

  &::before {
    background: $primary-color;
  }

  &:active {
    background: $primary-color;
    color: $white;
    border: 1px solid $white;
  }

  &:hover {
    background: $white;
    color: $white;
    border: 1px solid $white;
  }
}

.btn-primary-outline {
  border: 1px solid $primary-color;
  color: $primary-color;
  background: transparent;

  &::before {
    background: $white;
  }

  &:hover {
    background: $primary-color;
    color: $primary-color;
  }

  &:active {
    background: $primary-color;
    color: $white;
  }
}